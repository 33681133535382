
























































.table-pagination {
  padding: 31px 20px 48px 38px;

  .ant-pagination-item {
    margin: 0;
    border-color: #D5D9E1;
    border-radius: 0;

    .ant-pagination-item-link {
      border-radius: 0;
    }

    &-active {
      color: #0059A3;
      background: rgba(0, 89, 163, 0.1);
    }
  }

  .ant-pagination-prev,
  .ant-pagination-next {
    margin: 0;

    a {
      display: flex;
      justify-content: center;
      align-items: center;
      color: #D5D9E1;
    }
  }

  .ant-pagination-prev {
    a {
      border-radius: 4px 0 0 4px;
    }
  }

  .ant-pagination-next {
    a {
      border-radius: 0 4px 4px 0;
    }
  }

  .ant-select-selection--single .ant-select-selection__rendered {
    margin-right: 27px;
  }
}
